import { useContext } from 'react';

import { BUSINESS_PROV_SELECTOR_FIELDNAME } from '../components/business/checkout/CheckoutBusinessProvSelect';
import { CLIENT_SELECTOR_FIELDNAME } from '../components/business/checkout/CheckoutClientSelect';
import { DEPOSIT_FIELDNAME } from '../components/business/checkout/CheckoutDeposit';
import { CITY_FIELDNAME, COUNTRY_FIELDNAME, POSTALCODE_FIELDNAME, STREET_FIELDNAME } from '../components/business/checkout/CheckoutFieldsAddress';
import { FIRST_NAME_FIELDNAME, LANGUAGE_FIELDNAME, LAST_NAME_FIELDNAME } from '../components/business/checkout/CheckoutFieldsCustomer';
import { SEND_BOOKING_MAIL_FIELDNAME } from '../components/business/checkout/CheckoutMailToggle';
import { MERGE_ORDER_FIELDNAME } from '../components/business/checkout/CheckoutMergeOrderCheckbox';
import { OPT_IN_FIELDNAME } from '../components/business/checkout/CheckoutOptIn';
import { PAYMENTLINK_TEMPLATE_FIELDNAME } from '../components/business/checkout/CheckoutPaymentLinkTemplate';
import { PAYMENT_METHOD_FIELDNAME } from '../components/business/checkout/CheckoutPaymentMethod';
import { CHECKOUT_EXTRA_DATA_FIELDNAME } from '../components/business/checkout/form/CheckoutFieldsExtraData';
import { EMAIL_FIELDNAME } from '../components/generic/form/InputEmail';
import { PHONE_FIELDNAME } from '../components/generic/form/InputPhone';
import { CoreContext } from '../context/CoreContext';
import { copyNonEmptyKeys } from '../utils/arrayUtil';
import { getBrowserLocales } from '../utils/langUtil';
import useReadManufacturer from './useReadManufacturer';
import { NO_PAYMENT_METHOD, PAYMENT_LINK_METHOD } from './useReadPaymentMethods';

const useReadDefaultPaymentData = () => {
  const { coreData } = useContext(CoreContext);
  const { booking_engine: bookingEngine, user: loggedUser, show_all_payment_solutions: showAllPaymentSolutions } = coreData;
  const { team, business_provider: forcedBusinessProvider, booking_engine_specific_data: checkoutExtraData } = bookingEngine;

  const { canDisplaySelector } = useReadManufacturer();

  const getDefaultPaymentMethodFormData = () => {
    if (!loggedUser) return null;
    if (!showAllPaymentSolutions && coreData.is_manufacturer) return NO_PAYMENT_METHOD;
    return showAllPaymentSolutions ? NO_PAYMENT_METHOD : PAYMENT_LINK_METHOD;
  };

  const getDefaultExtraDataFormData = () => {
    const defaultExtraData = {};
    if (checkoutExtraData == null) return defaultExtraData;

    for (const extraData of checkoutExtraData) {
      defaultExtraData[extraData.specific_person_data.label] = '';
    }
    return defaultExtraData;
  };

  const DEFAULT_PAYMENT_DATA = {
    [CLIENT_SELECTOR_FIELDNAME]: { value: null },
    [MERGE_ORDER_FIELDNAME]: false,
    [FIRST_NAME_FIELDNAME]: '',
    [LAST_NAME_FIELDNAME]: '',
    [EMAIL_FIELDNAME]: '',
    [PHONE_FIELDNAME]: '',
    [LANGUAGE_FIELDNAME]: getBrowserLocales()[0], // for example we might have ["fr", "ca"] so we use the main locale
    [STREET_FIELDNAME]: '',
    [CITY_FIELDNAME]: '',
    [POSTALCODE_FIELDNAME]: '',
    [COUNTRY_FIELDNAME]: team.country,
    [PAYMENT_METHOD_FIELDNAME]: getDefaultPaymentMethodFormData(),
    [PAYMENTLINK_TEMPLATE_FIELDNAME]: '',
    [BUSINESS_PROV_SELECTOR_FIELDNAME]: forcedBusinessProvider ? forcedBusinessProvider.id : null,
    [SEND_BOOKING_MAIL_FIELDNAME]: '',
    [OPT_IN_FIELDNAME]: false,
    [DEPOSIT_FIELDNAME]: true,
    [CHECKOUT_EXTRA_DATA_FIELDNAME]: getDefaultExtraDataFormData(),
  };

  return canDisplaySelector ? DEFAULT_PAYMENT_DATA : getLoggedInUserAsPaymentData(DEFAULT_PAYMENT_DATA, loggedUser);
};
export default useReadDefaultPaymentData;

export const updatePaymentDataWithSelectedClient = (paymentData, selectedClient, defaultPaymentData, areChoicesStaffs) => {
  const newPaymentData = { ...defaultPaymentData };

  copyNonEmptyKeys(selectedClient, newPaymentData, [
    FIRST_NAME_FIELDNAME,
    LAST_NAME_FIELDNAME,
    EMAIL_FIELDNAME,
    PHONE_FIELDNAME,
    LANGUAGE_FIELDNAME,
    STREET_FIELDNAME,
    CITY_FIELDNAME,
    POSTALCODE_FIELDNAME,
    COUNTRY_FIELDNAME,
  ]);

  copyNonEmptyKeys(paymentData, newPaymentData, [PAYMENT_METHOD_FIELDNAME, PAYMENTLINK_TEMPLATE_FIELDNAME, SEND_BOOKING_MAIL_FIELDNAME], true);

  newPaymentData[CLIENT_SELECTOR_FIELDNAME] = {
    value: selectedClient['value'],
    hasMailAddress: selectedClient[EMAIL_FIELDNAME] != null && selectedClient[EMAIL_FIELDNAME].trim() != '',
    areChoicesStaffs: areChoicesStaffs,
  };
  return newPaymentData;
};

const getLoggedInUserAsPaymentData = (defaultPaymentData, loggedUser) => {
  const newPaymentData = { ...defaultPaymentData };

  // if you want to sync more data of the logged user then add fields here
  copyNonEmptyKeys(loggedUser, newPaymentData, [FIRST_NAME_FIELDNAME, LAST_NAME_FIELDNAME, EMAIL_FIELDNAME, PHONE_FIELDNAME]);

  return newPaymentData;
};
