import { useContext } from 'react';

import { Divider, Flex, Text } from '@chakra-ui/react';
import { Plural } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { CoreContext } from '../../../context/CoreContext';
import ContainerPlural from '../../generic/layout/ContainerPlural';

const AvailabilityDateTime = ({ sessions, dateStyle = 'full', timeStyle = 'short', placesAvailable }) => {
  const { i18n } = useLingui();

  const { coreData } = useContext(CoreContext);
  const { show_nb_remaining_tickets: showNbPlaces } = coreData.booking_engine;

  const firstSession =
    sessions.length == 1
      ? sessions[0]
      : sessions.reduce(function (a, b) {
          return a.start_date < b.start_date ? a : b;
        });

  return (
    <>
      <Text fontSize="sm" fontWeight="bold" textTransform="capitalize">
        {i18n.date(firstSession.start_date, {
          dateStyle: dateStyle,
        })}
      </Text>

      <Flex>
        <Text fontSize="xs">
          {i18n.date(firstSession.start_date, {
            timeStyle: timeStyle,
          })}
        </Text>

        {placesAvailable && showNbPlaces && (
          <>
            <Divider orientation="horizontal" w="10px" borderColor="brand.color_main" alignSelf="center" mx="5px" />

            {/* chakra ui xs fontSize */}
            <ContainerPlural value={placesAvailable} style={{ fontSize: '0.75rem' }}>
              <Plural value={placesAvailable} one="place" other="places" />
            </ContainerPlural>
          </>
        )}
      </Flex>
    </>
  );
};

export default AvailabilityDateTime;
