import { useRef } from 'react';

import { useUpdateEffect } from 'usehooks-ts';

import { LOCAL_STORAGE_CART_ID, ROUTE_PAGE_CART, ROUTE_P5_CHECKOUT_DETAILS, ROUTE_P6_CHECKOUT, BUYNOW_FORM_ACTION } from '../constants';
import { devMessage } from '../settings/settings';
import { isArrEmpty } from '../utils/arrayUtil';
import { REQUEST_STATUS } from '../utils/requestUtil';
import useFormValidate from './useFormValidate';
import useNavigateBookingEngine from './useNavigateBookingEngine';
import usePostCart from './usePostCart';
import usePostLoadVariant from './usePostLoadVariant';
import useReadAvailability from './useReadAvailability';
import useReadAvailabilityBookMore from './useReadAvailabilityBookMore';
import useReadNavigationToCheckoutDetail from './useReadNavigationToCheckoutDetail';
import useReadStorageBookingEngine from './useReadStorageBookingEngine';
import useWriteCartData from './useWriteCartData';

const useFormSessionVariants = (preCartData, availabilities, product) => {
  const storedCartId = useReadStorageBookingEngine(LOCAL_STORAGE_CART_ID);
  const { isInvalidForm, invalidResources, duplicateParticipants } = useFormValidate();

  const { status: statusLoadVariants, itemsToDeleteForEdit, reloadAfterError } = usePostLoadVariant(availabilities, product === null);
  const { data, status: statusAddCart, writeCart, editCart, resetWriteCart } = usePostCart(storedCartId, availabilities);

  const { setCartDataToWrite, cartData, cartWriteSuccess, setCartWriteSuccess } = useWriteCartData();

  const { areDetailsRequired, isOrderComptoir } = useReadNavigationToCheckoutDetail(cartData);

  const { maxAvailablePlaces } = useReadAvailability(isArrEmpty(availabilities) ? null : availabilities[0], product);
  const canBookMore = useReadAvailabilityBookMore(availabilities, product, cartData);

  // ref because we dont want to trigger render
  // if we trigger a render, it will skip the submit
  // TODO In a few years change this with the event.submitter
  // Before safari 15.4 the event.submitter has a bug and is not working
  // cf: https://bugs.webkit.org/show_bug.cgi?id=229660
  // Also do the same in the shop form hook
  const requestedAction = useRef();
  const setRequestedAction = action => {
    requestedAction.current = action;
  };

  const onSubmitAddToCart = e => {
    e.preventDefault();

    if (isInvalidForm(e.nativeEvent.target)) {
      // prevent the button from staying in isLoading
      setRequestedAction(null);
      return;
    }

    // dirty fix for this: https://trello.com/c/qaONwidU/1058-bug-be
    // when we click and something was "natively" required then we are not aware of it in the code
    // this should have been managed differently by having a REQUEST_STATUS.NOT_STARTED first instead of LOADING
    // this would have a big refactoring impact though
    setRequestedAction(requestedAction.current.replace('before_submit_', ''));

    if (!itemsToDeleteForEdit) {
      writeCart(preCartData);
      return;
    }

    const hasModifiedData = editCart(preCartData);
    if (!hasModifiedData) {
      setCartWriteSuccess(true);
    }
  };

  // When the cart backend query finished
  useUpdateEffect(() => {
    if (statusAddCart === REQUEST_STATUS.OK) {
      setCartDataToWrite(data);
    } else if (statusAddCart == REQUEST_STATUS.ERROR) {
      setRequestedAction(null);
      resetWriteCart();
      reloadAfterError();
    }
  }, [data, statusAddCart]);

  // when the cart success (and data) are set
  const navigate = useNavigateBookingEngine();
  useUpdateEffect(() => {
    const isBuyNow = requestedAction.current === BUYNOW_FORM_ACTION;
    setRequestedAction(null);

    if (!cartWriteSuccess) return;

    if (isBuyNow) {
      navigate(areDetailsRequired && isOrderComptoir ? ROUTE_P5_CHECKOUT_DETAILS : ROUTE_P6_CHECKOUT);
      return;
    }

    const cartUrl = `${ROUTE_PAGE_CART}/${cartData.cartOrderId}`;
    devMessage('Navigating to cart:', cartUrl);
    navigate(cartUrl, { state: { queryBackend: false } });
  }, [cartWriteSuccess]);

  const canDisplayVariantsAsQuantity = () => {
    // == to catch undefined too
    if (preCartData == null || preCartData.defaultVariant == null) return false;

    // Variant displayed as quantity instead of list if:
    // - no other price than the main (so cannot select any price)
    // - no options
    // - no participant detail
    // - and can add variants
    // So that's basically if the variant has no data, the user will save time entering a qty
    // instead of having to click N times on add
    return (
      !preCartData.defaultVariant.custom_prices.length &&
      !preCartData.defaultVariant.canSelectPrice &&
      !preCartData.defaultVariant.custom_options.length &&
      preCartData.defaultVariant.canAddVariant &&
      preCartData.defaultVariant.participantDetails === null
    );
  };

  return {
    onSubmitAddToCart,
    setRequestedAction,
    statusLoadVariants,
    statusAddCart,
    requestedAction,
    itemsToDeleteForEdit,
    invalidResources,
    duplicateParticipants,
    maxAvailablePlaces,
    // onEdit will always be true
    canBookMore: itemsToDeleteForEdit ? true : canBookMore,
    displayVariantsAsQuantity: canDisplayVariantsAsQuantity(),
  };
};

export default useFormSessionVariants;
