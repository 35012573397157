/**
 * This is the equivalent of the gtm integration script below
 * We do it this way to be able to include it dynamically
 * Source: https://www.learnbestcoding.com/post/22/reactjs-using-google-analytics-tag-manager
 * Example usage in a useEffectOnce : loadGoogleTagManager(window, document, 'script', 'dataLayer', 'GTM-XXX');
 * 
    <!-- Google Tag Manager -->
    <script>
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-XXX');
    </script>
    <!-- End Google Tag Manager -->
 *   
 */

import { getAllVariantsTotal } from './hooks/useCalcSessionVariantsTotal';
import { devMessage, settings } from './settings/settings';
import { findArrayItemById } from './utils/arrayUtil';

export const loadGoogleTagManager = (w, d, s, l, i) => {
  w[l] = w[l] || [];
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  const dl = l != 'dataLayer' ? '&l=' + l : '';
  const gtmUrl = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;

  // To avoid multiple installations of google tag manager detected warning
  if (scriptExists(gtmUrl)) return;
  devMessage('Loading ' + i);
  if (settings.DEBUG && !settings.GTM_ENABLE_IN_DEV) {
    return;
  }

  const f = d.getElementsByTagName(s)[0];
  const j = d.createElement('script');
  j.async = true;
  j.src = gtmUrl;
  f?.parentNode?.insertBefore(j, f);
};

const scriptExists = url => {
  const scripts = document.getElementsByTagName('script');
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src == url) return true;
  }
  return false;
};

const gtmPush = data => {
  window.dataLayer?.push({ ...data, yoplanning: true });
  window.dataLayer?.push({ yoplanning: undefined });
};

export async function gtmPushAddSessionToCart(cartData, currency) {
  if (cartData.availabilities && cartData.availabilities.length > 0) {
    const itemPrice = getAllVariantsTotal(cartData.product.price_per_participant, cartData.availabilities);

    gtmPush({
      event: 'add_to_cart',
      ecommerce: {
        currency: currency,
        value: itemPrice,
        items: [
          {
            item_name: cartData.product.title,
            item_variant: 'session',
            quantity: cartData.availabilities.length,
          },
        ],
      },
    });
  }
}

export async function gtmPushAddVoucherToCart(selectedVoucher, teamCurrency) {
  gtmPush({
    event: 'add_to_cart',
    ecommerce: {
      currency: teamCurrency,
      value: selectedVoucher.price * selectedVoucher.quantity,
      items: [
        {
          item_name: selectedVoucher.label,
          item_variant: 'voucher',
          price: selectedVoucher.price,
          quantity: selectedVoucher.quantity,
        },
      ],
    },
  });
}

export async function gtmPushViewItem(itemTitle) {
  gtmPush({
    event: 'view_item',
    ecommerce: {
      items: [
        {
          item_name: itemTitle,
        },
      ],
    },
  });
}

export async function gtmPushBeginCheckout(storedCartId, totalPrice, currency) {
  if (storedCartId == null) return;

  gtmPush({
    event: 'begin_checkout',
    ecommerce: {
      value: totalPrice.tax_included,
      currency: currency,
      cart_id: storedCartId,
    },
  });
}

export async function gtmPushPurchase(cartData, currency, callbackOnFired) {
  if (cartData == null || cartData.cartOrderId == null || cartData.totalPrice == null) return callbackOnFired();

  const eventData = {
    event: 'purchase',
    eventCallback: callbackOnFired,
    ecommerce: {
      transaction_id: cartData.cartOrderId,
      transaction_source: cartData.cartSource,
      value: cartData.totalPrice.tax_included,
      currency: currency,
      items: formatPurchasedItems(cartData),
    },
  };
  console.log('FIRING PURCHASE', eventData);
  gtmPush(eventData);
}

const formatPurchasedItems = cartData => {
  const items = [];

  for (const entry of cartData.ocSessionsWithResources) {
    const product = findArrayItemById(entry[1].productId, cartData.products);
    const productName = product != null ? product.title : 'Unknown product ' + entry[1].productId;

    for (const ava of entry[1].availabilities) {
      items.push({
        item_name: productName,
        item_variant: 'session',
        price: ava.price,
        quantity: ava.quantity,
      });

      for (const opt of ava.resources) {
        const resource = findArrayItemById(opt.resource_id, cartData.resources);
        const resourceName = resource != null ? resource.label : 'Unknown option ' + opt.resource_id;

        items.push({
          item_name: resourceName,
          item_variant: 'session_option',
          price: opt.price,
          quantity: opt.quantity,
        });
      }
    }
  }

  for (const voucher of cartData.ocVouchers) {
    items.push({
      item_name: voucher.gift_voucher.label,
      item_variant: 'voucher',
      price: voucher.price,
      quantity: voucher.quantity,
    });
  }

  for (const resource of cartData.ocSingleResources) {
    const resourceObj = findArrayItemById(resource.resource_id, cartData.resources);
    const resourceName = resourceObj != null ? resourceObj.label : 'Unknown resource ' + resource.resource_id;

    items.push({
      item_name: resourceName,
      item_variant: 'resource',
      price: resource.price,
      quantity: resource.quantity,
    });
  }

  return items;
};
