import { useMemo } from 'react';

import {
  Button,
  Center,
  Divider,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { MdClose } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';

import useIsMobile from '../../hooks/useIsMobile';
import { devMessage, settings } from '../../settings/settings';
import { BTN_ICON_BOXSIZE } from '../../utils/styleUtil';
import { commonButtonProps } from './button/ButtonLink';

export const closeBookingEngine = closingCode => {
  devMessage('Closing booking engine');

  // This is specific for the IOS app
  if (window.isNativeIos) {
    window.location.replace(settings.YOPLANNING_ROOT_URL);
  }
  // This sends a message to the window containing our iframe (if applicable)
  // Then our integration code kills the iframe
  else {
    window.parent.postMessage(closingCode ?? 'closeBookingEngine', '*');
  }
};

export const insideIframe = () => window.self !== window.top;

const ExitActionContainer = () => {
  const isMobile = useIsMobile();
  const [queryParams] = useSearchParams();
  const displayExitButton = useMemo(() => canDisplayExitButton(queryParams), []);

  const { onOpen, onClose, isOpen } = useDisclosure();

  const onExit = e => {
    e.preventDefault();
    onClose();
    closeBookingEngine();
  };

  if (!displayExitButton) return <></>;

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="left-end">
      <PopoverTrigger>
        {isMobile ? (
          <IconButton icon={<Icon as={MdClose} boxSize={BTN_ICON_BOXSIZE} />} />
        ) : (
          <Button {...commonButtonProps}>
            <Trans>Exit</Trans>
          </Button>
        )}
      </PopoverTrigger>

      <Portal>
        <PopoverContent textAlign="center">
          <PopoverArrow />
          <PopoverBody>
            <Text>
              <Trans>Are you sure you want to leave ?</Trans>
            </Text>
            <Center p="1rem">
              <Button {...commonButtonProps} onClick={onExit}>
                <Trans>Yes, leave</Trans>
              </Button>
              <Divider maxW="30px" mx="1rem" />
              <Button {...commonButtonProps} variant="outline" onClick={onClose}>
                <Trans>No, stay</Trans>
              </Button>
            </Center>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default ExitActionContainer;

const canDisplayExitButton = queryParams => {
  // We display it when in an iframe that is not embedded (= when we're in popups)
  // Also in the Yoplanning App Ios (because they have no back button)
  const isEmbedded = queryParams.has('bedisplay') && queryParams.get('bedisplay') == 'embed';
  const displayExitButton = (insideIframe() && !isEmbedded) || window.isNativeIos;

  // In dev we want the exit button so that we can test
  if (settings.DEBUG) {
    devMessage('Displaying exit btn because DEV. - PROD display is:', displayExitButton);
    return true;
  }
  return displayExitButton;
};
