import { useState } from 'react';

import { useUpdateEffect } from 'usehooks-ts';

import { CLIENT_SELECTOR_FIELDNAME } from '../components/business/checkout/CheckoutClientSelect';
import { CHECKOUT_EXTRA_DATA_FIELDNAME } from '../components/business/checkout/form/CheckoutFieldsExtraData';
import { PHONE_COUNTRYCODE_FIELDNAME, PHONE_FIELDNAME } from '../components/generic/form/InputPhone';
import { apiGetClientPreviousOrder } from '../constants';
import { findArrayItemByKey } from '../utils/arrayUtil';
import useFetchClientPreviousOrder from './useFetchClientPreviousOrder';
import useFormValidate from './useFormValidate';
import usePostCheckout from './usePostCheckout';
import useReadDefaultPaymentData, { updatePaymentDataWithSelectedClient } from './useReadDefaultPaymentData';
import useReadPaymentMethods from './useReadPaymentMethods';

const useFormCheckout = (cartMainClientId, setUnavailableItems, clientSelectorChoices, areChoicesStaffs, currency, totalPrice) => {
  const { isInvalidForm } = useFormValidate();

  const { data, status, writeCheckout } = usePostCheckout(cartMainClientId, currency, totalPrice);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const paymentMethods = useReadPaymentMethods();

  const defaultPaymentData = useReadDefaultPaymentData();
  const [paymentData, setPaymentData] = useState({ ...defaultPaymentData });

  const { setFetchOrderUrl, previousOrderDetails } = useFetchClientPreviousOrder();

  const handleOnChange = (fieldName, newValue, additionalData) => {
    if (fieldName === PHONE_FIELDNAME) {
      return handleChangePhone(newValue, additionalData);
    } else if (fieldName === CLIENT_SELECTOR_FIELDNAME) {
      return handleChangeClientSelector(newValue);
    } else if (fieldName === CHECKOUT_EXTRA_DATA_FIELDNAME) {
      return handleChangeExtraData(newValue, additionalData);
    }

    // standard cases
    setPaymentData({ ...paymentData, [fieldName]: newValue });
  };

  const handleChangePhone = (phoneValue, phoneCountryCode) => {
    setPaymentData({ ...paymentData, [PHONE_FIELDNAME]: phoneValue, [PHONE_COUNTRYCODE_FIELDNAME]: phoneCountryCode });
  };

  const handleChangeExtraData = (extraDataValue, extraDataInternalLabel) => {
    const currentExtraData = { ...paymentData[CHECKOUT_EXTRA_DATA_FIELDNAME] };
    currentExtraData[extraDataInternalLabel] = extraDataValue;
    setPaymentData({ ...paymentData, [CHECKOUT_EXTRA_DATA_FIELDNAME]: currentExtraData });
  };

  const handleChangeClientSelector = newValue => {
    // When selecting a client, we will update several fields (or reset if client was unselected)
    const selectedClient = newValue ? findArrayItemByKey('value', newValue, clientSelectorChoices) : null;
    if (!selectedClient) {
      setFetchOrderUrl(null);
      setPaymentData({ ...defaultPaymentData });
      return;
    }

    const newPaymentData = updatePaymentDataWithSelectedClient(paymentData, selectedClient, defaultPaymentData, areChoicesStaffs);
    setFetchOrderUrl(apiGetClientPreviousOrder(selectedClient.id));
    setPaymentData(newPaymentData);
  };

  const dataProps = {
    onChange: handleOnChange,
    isRequired: true,
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (isInvalidForm(e.nativeEvent.target)) {
      return;
    }

    setIsSubmitting(true);
    writeCheckout(paymentData);
  };

  useUpdateEffect(() => {
    if (data && data.unavailable_items) {
      setUnavailableItems(data.unavailable_items);
      setIsSubmitting(false);
    }
  }, [data]);

  return {
    handleSubmit,
    paymentData,
    dataProps,
    isSubmitting,
    status,
    data,
    paymentMethods,
    previousOrderDetails,
  };
};

export default useFormCheckout;
