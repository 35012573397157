import SpecificDataCustom from '../../checkoutdetails/specificdata/SpecificDataCustom';

export const CHECKOUT_EXTRA_DATA_FIELDNAME = 'extra_data';

const CheckoutFieldsExtraData = ({ checkoutExtraData: extraData, paymentData, onChange }) => {
  const handleOnChange = (extraDataId, internalLabel, fieldValue, selectLabel) => {
    return onChange(CHECKOUT_EXTRA_DATA_FIELDNAME, fieldValue, internalLabel); // TODO SELECT
  };

  return (
    <>
      {extraData.map(data => (
        <SpecificDataCustom
          key={data.id}
          data={data.specific_person_data}
          requiredFromProps={data.required}
          onParticipantDataUpdate={handleOnChange}
          fieldValue={paymentData[CHECKOUT_EXTRA_DATA_FIELDNAME][data.specific_person_data.label]}
          useDynWidth={false}
        />
      ))}
    </>
  );
};

export default CheckoutFieldsExtraData;
