import { Icon, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { MdAddCircle } from 'react-icons/md';

import ButtonIcon from './ButtonIcon';

const ButtonAdd = ({ onClick, icon = MdAddCircle, addText, isDisabled, ...props }) => {
  const handleClick = e => {
    e.preventDefault();
    if (!isDisabled) onClick();
  };

  return (
    <>
      {addText && (
        <Text fontSize="sm" as="span" onClick={handleClick} cursor="pointer" color="brand.color_main" {...props}>
          <Trans>Add</Trans>
        </Text>
      )}
      <ButtonIcon icon={<Icon as={icon} boxSize="26px" />} onClick={handleClick} placeSelf="center" as="span" isDisabled={isDisabled} {...props} />
    </>
  );
};

export default ButtonAdd;
