import { useState } from 'react';

import { useEffectOnce, useUpdateEffect } from 'usehooks-ts';

import { apiGetCheckoutData } from '../constants';
import useFetch from './useFetch';
import useReadDeposit from './useReadDeposit';

const useFetchCheckoutData = (loggedUser, cartId) => {
  const [fetchUrl, setFetchUrl] = useState(null);
  const { data, status } = useFetch(fetchUrl);
  const [checkoutData, setCheckoutData] = useState(null);

  const { hasDeposit } = useReadDeposit();

  useEffectOnce(() => {
    // For now fetch checkout data only for deposits
    if (loggedUser != null || !hasDeposit) {
      setCheckoutData({});
      return;
    }

    setFetchUrl(apiGetCheckoutData(cartId));
  });

  useUpdateEffect(() => {
    setCheckoutData(data);
  }, [data]);

  return { checkoutData, checkoutDataStatus: status };
};

export default useFetchCheckoutData;
