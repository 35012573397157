import { Box, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { getFormattedCurrency } from '../../../utils/business/prices';
import InputCheckbox from '../../generic/form/InputCheckbox';

const textProps = {
  as: 'span',
};

export const DEPOSIT_FIELDNAME = 'deposit';

const CheckoutDeposit = ({ deposit, depositAmount, ...dataProps }) => {
  return (
    <InputCheckbox fieldName={DEPOSIT_FIELDNAME} {...dataProps} fieldValue={deposit} isRequired={false}>
      <Box fontSize="xs" pl={{ base: '.4rem', md: undefined }}>
        {depositAmount > 0 && (
          <>
            <Text {...textProps}>
              <Trans>Pay only the deposit</Trans>{' '}
            </Text>
            <Text {...textProps}> {getFormattedCurrency(depositAmount)}</Text>
          </>
        )}
        {depositAmount === 0 && (
          <Text {...textProps}>
            <Trans>Pay on site</Trans>
          </Text>
        )}
      </Box>
    </InputCheckbox>
  );
};

export default CheckoutDeposit;
