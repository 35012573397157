import { useContext, useRef } from 'react';

import { Alert, AlertIcon, Box, Center, CircularProgress } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';

import { LOCAL_STORAGE_CART } from '../../../constants';
import { CoreContext } from '../../../context/CoreContext';
import { gtmPushPurchase } from '../../../gtm';
import useCleanStorage from '../../../hooks/useCleanStorage';
import { CART_SOURCE_LOGGED_OFFICE } from '../../../hooks/useReadCartSource';
import useReadStorageBookingEngine from '../../../hooks/useReadStorageBookingEngine';
import useReadTeam from '../../../hooks/useReadTeam';
import { settings } from '../../../settings/settings';
import { addQueryParamToUrl } from '../../../utils/requestUtil';
import { ROUNDED_ELEMENT_PROPS } from '../../../utils/styleUtil';
import { closeBookingEngine } from '../../generic/ExitActionContainer';

const CheckoutSuccess = () => {
  const [queryParams] = useSearchParams();

  const cartData = useReadStorageBookingEngine(LOCAL_STORAGE_CART);
  const isOrderOffice = cartData != null && cartData.cartSource === CART_SOURCE_LOGGED_OFFICE;

  const { coreData } = useContext(CoreContext);
  const { success_url: successUrl } = coreData.booking_engine;

  // isOrderOffice IS VERY IMPORTANT
  // in online mode + iframe, the localStorage might be different before/after payment (because different origin)
  // so after payment cartData can be unreliable in this mode, so we dont want to check if cartSource is online
  // instead we read the office flag which is more reliable because no origin shit
  const doRedirection = useRef(successUrl && !isOrderOffice);

  const { teamCurrency } = useReadTeam();

  const { cleanAll } = useCleanStorage(false);

  // just go read below please ^_^'
  const handlePurchaseSuccess = basicRedirect => {
    cleanAll();
    console.log('basic?', basicRedirect, 'redir?', doRedirection.current, 'to:', successUrl);
    if (!doRedirection.current) return;

    if (basicRedirect) {
      window.top.location.href = successUrl;
      return;
    }

    window.top.location.href = addQueryParamToUrl(successUrl, 'yopopen=' + encodeURIComponent(window.location.href));
  };

  // THIS IS SHIT BECAUSE WE NEED TO HANDLE GTM/ANALYTICS PURCHASE EVENTS INSIDE IFRAMES
  // BUT AFTER PAYMENT WE'RE OUTSIDE OF THE IFRAME AND THE LOCAL STORAGE ISNT THERE BECAUSE ORIGIN IS DIFFERENT
  // SO WE HAVE TO PLAY WITH REDIRECT TO REOPEN THE IFRAME AND SEND THAT PURCHASE SHIT
  // surely there must be an easier solution but I couldn't find it
  useEffectOnce(() => {
    // A-1. Iframe silently reopened after purchase, we send the purchase and close the iframe
    const isPostPurchase = queryParams.has('bedisplay') && queryParams.get('bedisplay') == 'purchase';
    if (isPostPurchase) {
      console.log('isPostPurchase');
      gtmPushPurchase(cartData, teamCurrency, () => closeBookingEngine('closePurchase'));
      return;
    }

    // B-1. No GTM : clean + basic redirect
    const hasNoGtm = window.dataLayer == null || (settings.DEBUG && !settings.GTM_ENABLE_IN_DEV);
    if (hasNoGtm) {
      console.log('hasNoGtm');
      handlePurchaseSuccess(true);
      return;
    }

    // B-2. With GTM but empty storage : clean + advanced redirect (to get to A-1 after)
    const emptyStorage = cartData == null || cartData.cartOrderId == null || cartData.totalPrice == null;
    if (emptyStorage) {
      console.log('emptyStorage');
      handlePurchaseSuccess(false);
      return;
    }

    // B-3 : With GTM and storage : fire purchase + basic redirect
    console.log('gtm and storage');
    gtmPushPurchase(cartData, teamCurrency, () => handlePurchaseSuccess(true));
  });

  return (
    <Center>
      {doRedirection.current && <CircularProgress isIndeterminate color="blue.500" mt="1rem" />}

      {!doRedirection.current && (
        <Box bg="brand.background_main" w={{ base: '100%', md: '50%' }} {...ROUNDED_ELEMENT_PROPS}>
          <Alert status="success" rounded={4}>
            <AlertIcon />
            <Trans>Your order was successfully received, thank you!</Trans>
          </Alert>
        </Box>
      )}
    </Center>
  );
};

export default CheckoutSuccess;
