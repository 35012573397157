import { useMemo } from 'react';

import { Box, Divider, HStack, Spacer, Text, VStack } from '@chakra-ui/react';

import useIsMobile from '../../../../hooks/useIsMobile';
import { findArrayItemById } from '../../../../utils/arrayUtil';
import { getAvailabilitiesAndResourcesIds } from '../../../../utils/business/availabilities';
import { ROUNDED_ELEMENT_PROPS } from '../../../../utils/styleUtil';
import BorderLeftBox from '../../../generic/BorderLeftBox';
import AvailabilityDateTime from '../../availability/AvailabilityDateTime';
import CartElementBoxes from '../CartElementBoxes';
import CartElementDeleteAction from '../CartElementDeleteAction';
import CartElementDetails from '../CartElementDetails';
import CartElementEditAction from '../CartElementEditAction';
import CartProductImage from '../CartProductImage';
import CartSessionPrice from '../CartSessionPrice';

const CartElementSession = ({ groupedSessionGroups, cartData, isTaxIncluded, blockActions, showEdit }) => {
  const isMobile = useIsMobile();
  const StackElement = isMobile ? VStack : HStack;

  const { availabilities, countParticipants, countResources, productId, sessions, detailResources } = groupedSessionGroups;
  const { products, cartOrderId } = cartData;
  const product = useMemo(() => findArrayItemById(productId, products), [productId]);

  const cartElementPriceProps = {
    isTaxIncluded: isTaxIncluded,
    groupedSessionGroups: groupedSessionGroups,
  };

  return (
    <Box bg="brand.background_main" {...ROUNDED_ELEMENT_PROPS} position="relative">
      <StackElement spacing="24px">
        <CartProductImage productImage={product.description_image} />

        <VStack align="left" flexGrow="1" width="100%">
          {/* responsive padding fix to prevent overflow on edit/delete btns for md screens */}
          <Text fontSize="md" pr={{ base: undefined, md: '2rem' }}>
            {product.title}
          </Text>

          <BorderLeftBox>
            <HStack align="left" flexGrow="1" alignItems="baseline">
              <AvailabilityDateTime sessions={sessions} dateStyle="long" />

              {/* elements all come from the same session group id so we dont care, we take the first avalability */}
              <CartElementDetails sessionGroupId={availabilities[0].session_group_id} detailResources={detailResources} />
            </HStack>

            <CartElementBoxes nbParticipants={countParticipants} nbResources={countResources} />
          </BorderLeftBox>
        </VStack>
      </StackElement>

      {!isMobile && (
        <>
          <CartSessionPrice {...cartElementPriceProps} position="absolute" bottom="4px" right=".6rem" />

          {showEdit && <CartElementEditAction availabilities={availabilities} product={product} blockActions={blockActions} />}
          <CartElementDeleteAction
            cartOrderId={cartOrderId}
            getItemIdsToDelete={() => getAvailabilitiesAndResourcesIds(availabilities)}
            blockActions={blockActions}
          />
        </>
      )}

      {isMobile && (
        <>
          <Divider orientation="horizontal" mt="1rem" />
          <HStack alignItems="flex-end">
            {showEdit && <CartElementEditAction availabilities={availabilities} product={product} position="relative" blockActions={blockActions} />}

            <CartElementDeleteAction
              cartOrderId={cartOrderId}
              getItemIdsToDelete={() => getAvailabilitiesAndResourcesIds(availabilities)}
              position="relative"
              blockActions={blockActions}
            />
            <Spacer />
            <CartSessionPrice {...cartElementPriceProps} />
          </HStack>
        </>
      )}
    </Box>
  );
};

export default CartElementSession;
