import { useState } from 'react';

import { useUpdateEffect } from 'usehooks-ts';

import { apiDeleteFromCart } from '../constants';
import usePost from './usePost';
import useWriteCartData from './useWriteCartData';

const useDeleteFromCart = (storedCartId, isEdit = false) => {
  const [itemIdsToDelete, setItemIdsToDelete] = useState(null);
  const [formattedData, setFormattedData] = useState(null);
  const { data, status, executePost } = usePost(apiDeleteFromCart(), formattedData);

  const { setCartDataToWrite } = useWriteCartData();

  useUpdateEffect(() => {
    setFormattedData(formatDeleteCartData(storedCartId, itemIdsToDelete));
  }, [itemIdsToDelete]);

  useUpdateEffect(() => {
    // This can happen when react reloads state
    if (itemIdsToDelete !== null) {
      executePost();
    }
  }, [formattedData]);

  useUpdateEffect(() => {
    setCartDataToWrite(data);
  }, [status]);

  return { setItemIdsToDelete, status };
};

const formatDeleteCartData = (storedCartId, itemIdsToDelete) => {
  const formattedData = getInitialFormattedData(storedCartId);
  formattedData.item_ids = itemIdsToDelete;
  return formattedData;
};

const getInitialFormattedData = storedCartId => {
  return {
    order_id: storedCartId,
    item_ids: [],
  };
};

export default useDeleteFromCart;
